import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AccountPage,
  BlogPage,
  CategoriesPage,
  CitiesPage,
  LoginPage,
  ServicePage,
  SettingsPage,
  NotFound,
  CreateUpdatePost
} from "./pages";

const App = () => {
  
  return (
    <BrowserRouter>
        <Routes>
          <Route Component={BlogPage} path="/" />
          <Route Component={CreateUpdatePost} path="/post" />
          <Route Component={CreateUpdatePost} path="/post/:id" />
          <Route Component={LoginPage} path="/login" />
          <Route Component={AccountPage} path="/account" exact />
          <Route Component={CategoriesPage} path="/categories" />
          <Route Component={CitiesPage} path="/cities" />
          <Route Component={ServicePage} path="/service-names" />
          <Route Component={SettingsPage} path="/settings" />
          
          <Route Component={NotFound} path="*" />
        </Routes>
    </BrowserRouter>
  );
};

export default App;
