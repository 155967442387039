import { Box, MenuItem, MenuList, Popover, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAuthActions } from '../store'
import { useSelector } from 'react-redux'

export const AccountPopover = ({ anchorEl, onClose, open, ...other }) => {
  const { logout } = useAuthActions()
  const { user } = useSelector(state => state.users)
  const navigate = useNavigate()

  const handleSignOut = async () => {
    logout()
    onClose?.()
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: { width: '300px' }
      }}
      {...other}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2
        }}
      >
        <Typography variant='overline'>{`${user?.firstName} ${user?.lastName}`}</Typography>
        <Typography color='text.secondary' variant='body2'>
          {user?.email}
        </Typography>
      </Box>

      <MenuList
        disablePadding
        sx={{
          '& > *': {
            '&:first-of-type': {
              borderTopColor: 'divider',
              borderTopStyle: 'solid',
              borderTopWidth: '1px'
            },
            padding: '12px 16px'
          }
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('/account')
            onClose?.()
          }}
        >
          {' '}
          My Profile{' '}
        </MenuItem>
      </MenuList>

      <MenuList
        disablePadding
        sx={{
          '& > *': {
            '&:first-of-type': {
              borderTopColor: 'divider',
              borderTopStyle: 'solid',
              borderTopWidth: '1px'
            },
            padding: '12px 16px'
          }
        }}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </MenuList>
    </Popover>
  )
}