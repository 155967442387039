import { createSlice } from '@reduxjs/toolkit'
import * as actions from './actions'
import { endsWith } from '../utils'
import { createActionsHook } from '../../../utils/createActionsHook'

const initialState = {
  tokens: null,
  isPending: false,
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCity: (state, {payload}) => {
      state.userCity = payload;
    },
  },
  extraReducers: reducer =>
    reducer
      .addCase(
        actions.login.fulfilled,
        (state, { payload: { user, tokens } }) => {
          if (user.role === 'admin') {
            state.tokens = tokens
          }
        },
      )
      .addCase(actions.logout.fulfilled, (state) => state = initialState)   
      .addCase(
        actions.refreshToken.fulfilled,
        (state, { payload }) => {
          state.tokens = payload
        },
      )
      .addMatcher(
        action => action.type.endsWith('pending'),
        state => {
          state.isPending = true
        },
      )
      .addMatcher(
        action => endsWith(action.type, 'rejected', 'fulfilled'),
        state => {
          state.isPending = false
        },
      ),
})
const allActions = Object.assign(slice.actions, actions)
export const useAuthActions = createActionsHook(allActions)
export const path = slice.name
export default slice.reducer
