import axios from "axios";

const htmlEntities = {
  "&amp;": "&",
  "&lt;": "<",
  "&gt;": ">",
  "&quot;": '"',
  "&#39;": "'",
  "&apos;": "'",
  "&#x2F;": "/",
};

const decodeHTMLEntities = (text) => {
  return text.replace(
    /&(amp|lt|gt|quot|#39|apos|#x2F);/g,
    (match) => htmlEntities[match]
  );
};

export const postTranslator = async (obj, targetLanguage, lang) => {
  const translatedObject = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    try {
      let url = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyAMsKHDy7HFQ4qAYaqSWro_2MvL7c8-gBU`;
      if(lang){
        url += `&source=${lang}`
      }
      const translation = await axios.post(
        url,
        {
          q: obj[key],
          target: targetLanguage,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
      
      try {
        if (
          translation.data?.data?.translations &&
          translation.data.data.translations.length
        ) {
          translatedObject[key] = decodeHTMLEntities(
            translation.data.data.translations[0].translatedText
          );
        } else {
          translatedObject[key] = obj[key];
        }
      } catch (error) {
        translatedObject[key] = obj[key];
      }
    } catch (error) {
      translatedObject[key] = obj[key];
    }
    // }
  }
  return translatedObject;
};
