import { toast } from 'react-toastify'
import { getErrorMsg } from './get-error-msg'

const displayError = error => {
  const message = getErrorMsg(error) || 'Unknown error occurred'

  toast.error(message, {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  })
}

const displaySuccess = message => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  })
}

export { displayError, displaySuccess }
