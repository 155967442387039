import { Box, Button, Container, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePostsActions } from "../store/server/blog";
import { displayError } from "../utils/misc";
import { useSelector } from "react-redux";
import RichText from "../components/RichText/component";
import "./index.css";
import { postTranslator } from "../utils/postTranslator";

const langueges = {
  en: "English",
  uk: "Українська",
  ru: "Русский",
  es: "Español",
  cs: "Čeština",
  pl: "Polska",
  kk: "Қазақ",
  de: "Deutsch",
};

const Page = () => {
  const { getOnePost, updatePost, createPost } = usePostsActions();
  const { isPending } = useSelector((state) => state.posts);
  const { tokens } = useSelector(state => state.auth)

  const params = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState({
    body: "",
    image: "",
    title: "",
    translations: [],
  });
  const [selectedPost, setSelectedPost] = useState(post);

  const [selectedLang, setSelectedLang] = useState();
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchPost = async () => {
    const { payload, error } = await getOnePost(params.id);
    if (error) {
      return displayError(error.message);
    }
    setPost(payload);
    setSelectedPost(payload);
  };

  useEffect(() => {
    if (!!params?.id) {
      fetchPost();
    }
    // eslint-disable-next-line
  }, [params?.id]);

  const onSubmit = async () => {
    if (!selectedPost.title) return displayError("Post title is required");

    if (!post.image && !selectedImage)
      return displayError("Post image is required");

    if (post.id) {
      const formData = new FormData();
      formData.append("title", post.title);
      formData.append("body", post.body);
      formData.append('translations', JSON.stringify(post.translations))
      if (selectedImage) formData.append("image", selectedImage);
      const { error } = await updatePost({
        id: post.id,
        body: formData,
      });
      if (error) {
        return displayError(error.message);
      }
      navigate("/");
      return;
    }

    const formData = new FormData();
    formData.append("title", post.title);
    formData.append("body", post.body);
    formData.append('translations', JSON.stringify(post.translations))
    formData.append("image", selectedImage);
    
    const { error } = await createPost(formData);
    if (error) {
      return displayError(error.message);
    }
    navigate("/");
  };
  useEffect(() => {
    if(!tokens){
      navigate('/login')
    } 
// eslint-disable-next-line
  }, [tokens])
console.log(post);

  return (
    <Box
      component="main"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minHeight: "100vh",
        backgroundColor: "#e0f4ff",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            maxWidth: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: "20px",
          }}
        >
          <Button
            color="warning"
            variant="contained"
            onClick={() => {
              navigate(`/`);
            }}
          >
            <ArrowBackIcon />
            Back
          </Button>
          <div style={{ width: 10, height: 10 }} />
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              onSubmit();
            }}
            disabled={isPending}
          >
            Publish
          </Button>
        </Box>
        <Box
          sx={{
            maxWidth: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: 'wrap',
            justifyContent: "space-between",
            marginBlock: "10px",
          }}
        >
          <Button
            variant={!selectedLang ? "contained" : "secondary"}
            onClick={() => {
              setSelectedLang();
              setSelectedPost(post);
            }}
          >
            Origin
          </Button>
          {Object.keys(langueges).map((lang) => (
            <Button
            key={lang}
              variant={
                lang === selectedLang ? "contained" : "secondary"
              }
              onClick={() => {
                setSelectedLang(lang);
                setSelectedPost( post.translations.find(translate => translate.lang === lang) ?? {
                  title: '',
                  body: '',
                  lang
                });
              }}
            >
              {langueges[lang]?.slice(0, 3)}
            </Button>
          ))}
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!!selectedLang && (
            <Button
              color="info"
              variant="contained"
              sx={{ mb: "10px" }}
              onClick={async () => {
                const { translations, image, ...origin } = post;
                const res = await postTranslator(origin, selectedLang);
                console.log(res);
                
                const newTranslations = translations.filter(
                  (item) => item.lang !== selectedLang
                );
                newTranslations.push({...selectedPost, ...res});
                setPost({ ...post, translations: newTranslations });
                setSelectedPost({ ...selectedPost, ...res });
              }}
            >
              {`Translate ${post.body.length}/5000`}
            </Button>
          )}
          {(post && post?.image) || selectedImage ? (
            <img
              name="image"
              src={
                selectedImage ? URL.createObjectURL(selectedImage) : post?.image
              }
              alt="category"
              style={{
                width: "100%",
                maxWidth: "800px",
                borderRadius: 10,
                alignSelf: "center",
                maxHeight: "450px",
                objectFit: "cover",
              }}
            />
          ) : null}
          <Button
            variant="contained"
            component="label"
            sx={{
              maxWidth: "200px",
              alignSelf: "center",
            }}
          >
            Upload Image
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => {
                if(!!e.target.files.length) {
                  setSelectedImage(e.target.files[0])
                };
              }}
            />
          </Button>
          <h2
            style={{
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            {selectedPost.title}
          </h2>
          <div
            className="post-wrapper"
            dangerouslySetInnerHTML={{
              __html: selectedPost.body,
            }}
          />
        </div>
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          label="Post title"
          sx={{
            backgroundColor: "#FFF",
            borderRadius: "5px",
          }}
          value={selectedPost.title || ""}
          onChange={(e) => {
            setSelectedPost({
              ...selectedPost,
              title: e.target.value,
            });
          }}
        />
        <Box sx={{ mt: "10px" }}>
          <RichText
            value={selectedPost.body}
            setValue={(item) => {
              if (!!selectedLang) {
                const translations = post.translations.filter(
                  (item) => item.lang !== selectedLang
                );
                translations.push({ ...selectedPost, body: item });
                setPost({ ...post, translations: translations });
              } else {
                setPost({ ...selectedPost, body: item });
              }
              setSelectedPost({ ...selectedPost, body: item });
              window.scrollTo(0, 120);
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
