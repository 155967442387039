import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { displayError, displaySuccess } from "../utils/misc";
import { useNavigate } from "react-router-dom";
import { useAuthActions } from "../store";

const Login = () => {
  const { login } = useAuthActions()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      password: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string().required("Phone number is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async () => {
      try {
        const { error } = await login({creds: formik.values})
        if( error ){
          return displayError(error.message);
        }
        navigate('/')
        displaySuccess('Login success!')
      } catch (error) {
        if (error.message === "You are not authorized to access this page")
          return 

        displayError(error);
      }
    },
  });

  return (
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 3 }}>
              <Typography color="textPrimary" variant="h4">
                Sign in
              </Typography>
            </Box>

            <TextField
              error={Boolean(
                formik.touched.phoneNumber && formik.errors.phoneNumber
              )}
              fullWidth
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              label="Phone Number"
              margin="normal"
              name="phoneNumber"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.phoneNumber}
              variant="outlined"
              placeholder="+380.............."
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign In Now
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
  );
};

export default Login;
