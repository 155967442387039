import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  Grid,
} from "@mui/material";
import { Sentry } from "react-activity";
import { Search as SearchIcon } from "../icons/search";

export const ToolBox = ({ title, onSearch, loading, onClick, addTitle }) => {
  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: -1,
        }}
      >
        <Typography sx={{ m: 1 }} variant="h4">
          {title}
        </Typography>

        <Box sx={{ m: 1 }}>
          {loading ? (
            <Button
              startIcon={
                <Sentry
                  color="#DA6868"
                  size={20}
                  speed={0.5}
                  animating={true}
                />
              }
              sx={{ mr: 1 }}
              color="error"
            >
              Loading...
            </Button>
          ) : null}

          {onClick ? (
            <Button color="primary" variant="contained" onClick={onClick}>
              {addTitle || `Add ${title}`}
            </Button>
          ) : null}
        </Box>
      </Box>

      {onSearch ? (
        <Box sx={{ mt: 3 }}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box sx={{ maxWidth: 500 }}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon color="action" fontSize="small">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder={`Search ${title}`}
                      variant="outlined"
                      onChange={onSearch}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      ) : null}
    </Box>
  );
};
