import axios from 'axios';
import {store} from '../../store';
import * as actions from './auth/actions';
import { baseURL } from '../../utils/constants';

export const API = axios.create({
  baseURL: baseURL,
});

/** Request interceptor for API calls */
API.interceptors.request.use(
  async config => {
    const state = store.getState();
    config.headers = {
      Authorization: `Bearer ${state.auth.tokens?.access.token}`,
      Accept: 'application/json',
      ...config.headers,
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

/** Response interceptor for API calls */
API.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    const state = store.getState().auth;
    // console.log(
    //   'Error:',
    //   error?.response?.status,
    //   error?.response?.data?.message,
    // );
    if (
      [401, 403].includes(error?.response?.status) &&
      !originalRequest._retry
    ) {
      if (error?.response?.data?.message === 'Please authenticate') {
        return store.dispatch(actions.logout());
      }
      originalRequest._retry = true;
      await store.dispatch(actions.refreshToken(state.tokens?.refresh.token));
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${state.tokens?.access.token}`;
      return API(originalRequest);
    }
    return Promise.reject(error?.response?.data?.message);
  },
);
