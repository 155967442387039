import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

export const Logo = styled(props => {
  const { variant, ...other } = props
  return <img src='/static/logo.png' alt='Logo' {...other} />
})``

Logo.defaultProps = {
  variant: 'primary'
}

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary'])
}
