import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../dataServise'
import { baseURL } from "../../../utils/constants";

const apiUrl = `${baseURL}/users`

export const getMe = createAsyncThunk('ME', async (_, thunk) => {
  try {
    const { data } = await API.get(`${apiUrl}/me`)
    return data
  } catch (exception) {
    if (exception.response?.data.message) {
      return thunk.rejectWithValue(exception.response.data.message)
    } else throw exception
  }
})

export const updateMe = createAsyncThunk(
  'UPDATE_ME',
  async (body, thunk) => {
    try {
      const {data} = await API.put(`${apiUrl}/me`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return data
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message)
      } else throw exception
    }
  },
)

export const deleteMe = createAsyncThunk('ME', async (password, thunk) => {
  try {
    await API.delete(`${apiUrl}/me?password=${password}`)
  } catch (exception) {
    if (exception.response?.data.message) {
      return thunk.rejectWithValue(exception.response.data.message)
    } else throw exception
  }
})
