import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions'
import { endsWith } from '../utils';
import { createActionsHook } from '../../../utils/createActionsHook';

const initialState = {
  categories: [],
  subCategories: [],
  isPending: false,
}

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: reducer => reducer
    .addCase(actions.getCountries.fulfilled, (state, {payload}) => {      
      state.countries = payload.results
    })
    .addCase(actions.getCities.fulfilled, (state, {payload}) => {
      state.cities = payload
    })
    .addCase(actions.getCategories.fulfilled, (state, {payload}) => {
      state.categories = payload
    })
    .addCase(actions.getSubCategories.fulfilled, (state, {payload}) => {
      state.subCategories = payload.results
    })
    .addMatcher(action => action.type.endsWith('pending'), (state) => {
      state.isPending = true;
    })
    .addMatcher(action => endsWith(action.type, 'rejected', 'fulfilled'), (state) => {
      state.isPending = false;
    })
})
const allActions = Object.assign(slice.actions, actions)
export const useCommonActions = createActionsHook(allActions)

export default slice.reducer;