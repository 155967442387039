import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DashboardNavbar } from "./dashboard-navbar";
import { DashboardSidebar } from "./dashboard-sidebar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUsersActions } from "../store";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 280,
  },
}));

export const DashboardLayout = (props) => {
  const { children } = props;
  const { getMe } = useUsersActions()
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const { tokens } = useSelector(state => state.auth)

  const navigate = useNavigate()
  useEffect(() => {
    if(!tokens){
      navigate('/login')
    } else {
      getMe()
    }
// eslint-disable-next-line
  }, [tokens])
  
  return (
    <div>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {children}
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </div>
  );
};
