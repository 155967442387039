import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../dataServise";
import { baseURL } from "../../../utils/constants";

const apiUrl = `${baseURL}`;

export const getCountries = createAsyncThunk("COUNTRIES", async (_, thunk) => {
  try {
    const { data } = await API.get(`${apiUrl}/countries`);
    return data;
  } catch (exception) {
    if (exception.response?.data.message) {
      return thunk.rejectWithValue(exception.response.data.message);
    } else throw exception;
  }
});

export const getCities = createAsyncThunk(
  "CITIES",
  async ({page, limit=20}, thunk) => {
    try {
      const { data } = await API.get(
        `${apiUrl}/cities?page=${page}&limit=${20}`
      );
      
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);
export const createCities = createAsyncThunk(
  "CREATE_CITY",
  async (body, thunk) => {
    try {
      const { data } = await API.post(
        `${apiUrl}/cities`, body
      );
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);
export const updateCity = createAsyncThunk(
  "UPDATE_CITY",
  async ({id, body}, thunk) => {
    try {
      const { data } = await API.put(
        `${apiUrl}/cities/${id}`, body
      );
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);
export const deleteCity = createAsyncThunk(
  "DELETE_CITY",
  async (id, thunk) => {
    try {
      const { data } = await API.delete(
        `${apiUrl}/cities/${id}`
      );
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const getCategories = createAsyncThunk(
  "CATEGORIES",
  async (_, thunk) => {
    try {
      const { data } = await API.get(`${apiUrl}/categories`);
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const updateCategories = createAsyncThunk(
  "UPDATE_CATEGORIES",
  async ({ id, body }, thunk) => {
    try {
      const { data } = await API.put(`${apiUrl}/categories/${id}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const createCategories = createAsyncThunk(
  "CREATE_CATEGORIES",
  async (body, thunk) => {
    try {
      const { data } = await API.post(`${apiUrl}/categories`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);
export const deleteCategories = createAsyncThunk(
  "DELETE_CATEGORIES",
  async (id , thunk) => {
    try {
      await API.delete(`${apiUrl}/categories/${id}`);
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const getSubCategories = createAsyncThunk(
  "SUB_CATEGORIES",
  async (_, thunk) => {
    try {
      const { data } = await API.get(`${apiUrl}/service-names`);
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const uploadFiles = createAsyncThunk(
  "UPLOAD_FILES",
  async (body, thunk) => {
    try {
      const { data } = await API.post(`${apiUrl}/uploads`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);
