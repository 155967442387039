import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';
import { createActionsHook } from '../../../utils/createActionsHook';
import { endsWith } from '../utils';


const initialState = {
  isPending: false,
  noreadedMessages: 0
}

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: { 
    clearBuyData: state => state.buyData = undefined,
    setNoReadedMessages: (state, {payload}) => {
      state.noreadedMessages=payload
    },
    resetUser: state => state = initialState
  },
  extraReducers: reducer => reducer
    .addCase(actions.getMe.fulfilled, (state, {payload}) => {
      state.user = payload
    })
    .addCase(actions.updateMe.fulfilled, (state, {payload}) => {
      state.user = payload
    })
    .addMatcher(action => action.type.endsWith('pending'), (state) => {
      state.isPending = true;
    })
    .addMatcher(action => endsWith(action.type, 'rejected', 'fulfilled'), (state) => {
      state.isPending = false;
    })
})
const allActions = Object.assign(slice.actions, actions)
export const useUsersActions = createActionsHook(allActions)

export default slice.reducer;