import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions'
import { endsWith } from '../utils';
import { createActionsHook } from '../../../utils/createActionsHook';


const initialState = {
  isPending: false,
  services: {}
}

const slice = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: reducer => reducer
    .addMatcher(actions => actions.type.endsWith('pending'), (state) => {
      state.isPending = true;
    })
    .addMatcher(actions => endsWith(actions.type, 'rejected', 'fulfilled'), (state) => {
      state.isPending = false;
    })
})
const allActions = Object.assign(slice.actions, actions)
export const useServicesActions = createActionsHook(allActions)

export default slice.reducer;