import { Avatar, Box, Card, CardContent, Divider, Typography } from '@mui/material'
import { rawURL } from '../../utils/constants'

export const AccountProfile = ({ user, ...props }) => (
  <Card {...props}>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Avatar
          src={user?._image?.min?.startsWith('https' || 'http') ? user?._image?.min : rawURL + '/' + user?._image?.min || ''}
          sx={{
            height: 64,
            mb: 2,
            width: 64
          }}
        />
        <Typography color='textPrimary' gutterBottom variant='h5'>
          {`${user?.firstName} ${user?.lastName}`}
        </Typography>
        <Typography color='textSecondary' variant='body2'>
          {user?.email}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    {/*    <CardActions>
      <Button color='primary' fullWidth variant='text'>
        Upload picture
      </Button>
        </CardActions> */}
  </Card>
)
