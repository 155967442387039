import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import * as ACTION_TYPES from 'redux-persist/es/constants';
import storage from 'redux-persist/lib/storage'


// import app from './app'
import auth from './server/auth'
import common from './server/common';
import users from './server/users';
import services from './server/services';
import settings from './server/settings';
import posts from './server/blog';


const reducers = combineReducers({
  auth,
  common,
  users,
  services,
  settings,
  posts,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app', 'auth'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          ACTION_TYPES.FLUSH,
          ACTION_TYPES.REHYDRATE, 
          ACTION_TYPES.PAUSE, 
          ACTION_TYPES.PERSIST, 
          ACTION_TYPES.PURGE, 
          ACTION_TYPES.REGISTER],
      },
    })

    return middlewares
  },
})

const persistor = persistStore(store)

export { store, persistor }
