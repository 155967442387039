import {
  Box,
  Container,
  Card,
  Button,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { DashboardLayout } from "../components/dashboard-layout";
import { useState, useEffect } from "react";
import { useSettingssActions } from "../store/server/settings";
import { useSelector } from "react-redux";

const Page = () => {
  const { getAppSettings, updateAppSettings } = useSettingssActions();
  const { isPending: isLoading } = useSelector((state) => state.settings);
  const [data, setData] = useState();

  const [values, setValues] = useState({
    reviewRewardPercentage: data?.reviewRewardPercentage || 0,
    bannerAmount: data?.bannerAmount || 0,
    bannerDuration: data?.bannerDuration || 0,
  });

  useEffect(() => {
    if (data) {
      // delete data.id;
      setValues({
        reviewRewardPercentage: data?.reviewRewardPercentage || 0,
        bannerAmount: data?.bannerAmount || 0,
        bannerDuration: data?.bannerDuration || 0,
      });
    }
  }, [data]);
  const getData = async () => {
    const { payload } = await getAppSettings();
    setData(payload);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <form autoComplete="off" noValidate>
            <Card>
              <CardHeader
                subheader="The information can be edited"
                title="App Settings"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      helperText="Please enter review reward percentage"
                      label="Review Reward Percentage"
                      name="reviewRewardPercentage"
                      onChange={handleChange}
                      required
                      value={values.reviewRewardPercentage}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      helperText="Please enter your banner amount"
                      label="Banner Amount"
                      name="bannerAmount"
                      onChange={handleChange}
                      required
                      value={values.bannerAmount}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Banner Duration"
                      name="bannerDuration"
                      onChange={handleChange}
                      required
                      value={values.bannerDuration}
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    updateAppSettings(values);
                  }}
                  disabled={isLoading}
                >
                  Save details
                </Button>
              </Box>
            </Card>
          </form>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Page;
