import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../dataServise'
import { baseURL } from "../../../utils/constants";

const apiUrl = `${baseURL}/admin/app-settings`

export const getAppSettings = createAsyncThunk(
  'GET_APP_SETTINGS',
  async (_, thunk) => {
    try {
      const { data } = await API.get(`${apiUrl}`)
      return data
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message)
      } else throw exception
    }
  },
)

export const updateAppSettings = createAsyncThunk(
  'UPDATE_APP_SETTINGS',
  async ( body, thunk) => {
    try {
      const { data } = await API.post(`${apiUrl}`, body)
      return data
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message)
      } else throw exception
    }
  },
)
