import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../dataServise'
import { baseURL } from "../../../utils/constants";

const apiUrl = `${baseURL}/admin/blogs`

export const getPosts = createAsyncThunk(
  'GET_POSTS',
  async ({page=1}, thunk) => {
    try {
      const { data } = await API.get(`${apiUrl}?page=${page}`)
      return data
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message)
      } else throw exception
    }
  },
)
export const getOnePost = createAsyncThunk(
  'GET_ONE_POST',
  async (id, thunk) => {
    try {
      const { data } = await API.get(`${apiUrl}/${id}`)
      return data
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message)
      } else throw exception
    }
  },
)

export const createPost = createAsyncThunk(
  'CREATE_POST',
  async (body, thunk) => {
    try {
      const { data } = await API.post(`${apiUrl}`, body)
      return data
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message)
      } else throw exception
    }
  },
)

export const updatePost = createAsyncThunk(
  'UPDATE_POST',
  async ({id, body}, thunk) => {
    try {
      const { data } = await API.put(`${apiUrl}/${id}`, body)
      return data
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message)
      } else throw exception
    }
  },
)

export const deletePost = createAsyncThunk(
  'DELETE_POST',
  async (id, thunk) => {
    try {
      const { data } = await API.delete(`${apiUrl}/${id}`)
      return data
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message)
      } else throw exception
    }
  },
)