import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { displayError } from "../../utils/misc";
import { useUsersActions } from "../../store";
import { useSelector } from "react-redux";

export const AccountProfileDetails = ({ user, ...props }) => {
  const {updateMe} = useUsersActions()
  const {isPending: isLoading} = useSelector(state => state.users)

  const [values, setValues] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    password: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };


  const onUpdateMe = async () => {
    const newValues = { ...values };
    if (!newValues.password) {
      delete newValues.password;
    }
    
    const { error } = await updateMe(newValues);
    if(error){
      return displayError(error.message)
    }

  };

  return (
    <form autoComplete="off" noValidate {...props}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                helperText="Please enter your first name"
                label="First name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                helperText="Please enter your last name"
                label="Last name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                onChange={handleChange}
                disabled
                value={user?.phoneNumber}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                onChange={handleChange}
                value={values.password}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              onUpdateMe();
            }}
            loading={isLoading}
            disabled={isLoading}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};
