import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../dataServise";
import { baseURL } from "../../../utils/constants";

const apiUrl = `${baseURL}/auth`;

export const login = createAsyncThunk(
  "LOGIN",
  async ({ creds, lang }, thunk) => {
    try {
      const { data } = await API.post(`${apiUrl}/login?lang=${lang}`, creds);
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const logout = createAsyncThunk("LOGOUT", async (_, _thunkAPI) => {
});

export const register = createAsyncThunk(
  "REGISTRATION",
  async ({ creds, lang }, thunk) => {
    try {
      const { data } = await API.post(`${apiUrl}/register?lang=${lang}`, creds);
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "FORGOT_PASSWORD",
  async ({ phoneNumber, lang }, thunk) => {
    try {
      await API.post(`${apiUrl}/forgot-password?lang=${lang}`, { phoneNumber });
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const changePhoneNumberSMS = createAsyncThunk(
  "CHANGE_PHONE_NUMBER_SMS",
  async (phoneNumber, thunk) => {
    try {
      await API.post(
        `${apiUrl}/change-phone-send-verification-sms`,
        phoneNumber
      );
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const changePhoneNumber = createAsyncThunk(
  "CHANGE_PHONE_NUMBER_SMS",
  async (creds, thunk) => {
    try {
      await API.post(`${apiUrl}/change-phone`, creds);
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "RESET_PASSWORD",
  async (creds, thunk) => {
    try {
      await API.post(`${apiUrl}/reset-password`, creds);
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const changePassword = createAsyncThunk(
  "RESET_PASSWORD",
  async (creds, thunk) => {
    try {
      await API.post(`${apiUrl}/change-password`, creds);
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const sendVerifySMS = createAsyncThunk("SMS", async (_, thunk) => {
  try {
    await API.post(`${apiUrl}/send-verification-sms`);
  } catch (exception) {
    if (exception.response?.data.message) {
      return thunk.rejectWithValue(exception.response.data.message);
    } else throw exception;
  }
});

export const verifyPhone = createAsyncThunk(
  "VERIFY",
  async (verificationCode, thunk) => {
    try {
      await API.post(`${apiUrl}/verify-phone`, verificationCode);
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);

export const refreshToken = createAsyncThunk(
  "REFRESH_TOKEN",
  async (token, thunk) => {
    try {
      const { data } = await API.post(`${apiUrl}/refresh-tokens`, {
        refreshToken: token,
      });
      return data;
    } catch (exception) {
      if (exception.response?.data.message) {
        return thunk.rejectWithValue(exception.response.data.message);
      } else throw exception;
    }
  }
);
