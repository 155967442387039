export function getErrorMsg(errorObj) {
  if (!errorObj) return "";
  if (typeof errorObj === "string") return errorObj;
  if (errorObj.message === "Network Error") return "No connection";
  const { response } = errorObj;

  if (response) {
    const { data } = response;

    if (data) return data.message || "Unknown Error occurred";
  }

  const { data } = errorObj;
  if (!data) return "Unknown error occurred";
  const { message } = data;
  if (message) return message;
  return "Unknown error occurred";
}
