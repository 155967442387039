import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { AppBar, Avatar, Box, IconButton, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { UserCircle as UserCircleIcon } from '../icons/user-circle'
import { AccountPopover } from './account-popover'
import { rawURL } from '../utils/constants'
import { useSelector } from 'react-redux'

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}))

export const DashboardNavbar = props => {
  const { onSidebarOpen, ...other } = props
  const settingsRef = useRef(null)

  const [openAccountPopover, setOpenAccountPopover] = useState(false)

  const { user } = useSelector(state => state.users)
  
  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280
          },
          width: {
            lg: 'calc(100% - 280px)'
          }
        }}
        {...other}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none',
                ml: 1
              }
            }}
          >
            <MenuIcon fontSize='small' color='primary' />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />

          <Avatar
            onClick={() => setOpenAccountPopover(true)}
            ref={settingsRef}
            sx={{
              cursor: 'pointer',
              height: 40,
              width: 40,
              ml: 2
            }}
            src={user?._image?.min?.startsWith('https' || 'http') ? user._image.min : rawURL + '/' + user?._image.min || ''}
          >
            <UserCircleIcon fontSize='small' />
          </Avatar>
        </Toolbar>
      </DashboardNavbarRoot>
      <AccountPopover
        anchorEl={settingsRef.current}
        open={openAccountPopover}
        onClose={() => setOpenAccountPopover(false)}
      />
    </>
  )
}
